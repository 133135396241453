import React from "react"
import { Container } from "@material-ui/core"
import SEO from "../seo"
import Listing from "../listing"
import { useUtilStyles } from "../utils"
import { graphql, useStaticQuery } from "gatsby"

export default function CategoryDetails({ tagType }) {
  const query = useStaticQuery(graphql`
    query result {
      allMdx(sort: { fields: frontmatter___date, order: DESC }) {
        nodes {
          childrenMdxPost {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            banner {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            date(formatString: "D-M-yyyy")
            tags
            recipe {
              title
              ingredients {
                name
                percentage
              }
            }
          }
        }
      }
    }
  `)

  const postsData = query.allMdx.nodes?.filter(val => {
    const filtertag = val.frontmatter.tags?.filter(val1 => val1 === tagType)
    if (filtertag?.length > 0) {
      return true
    } else {
      return false
    }
  })
  return (
    <>
      <SEO />
      <Container maxWidth="lg" className={useUtilStyles().topSpacer}>
        <h1
          class="MuiTypography-root MuiTypography-h1"
          style={{ textAlign: "center", textTransform: "uppercase" }}
        >
          {tagType}
        </h1>
        <section className={useUtilStyles().topSpacer}>
          <Listing posts={postsData || []} />
        </section>
      </Container>
    </>
  )
}
